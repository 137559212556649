import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './components/SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder
  .addTextInput({
    path: 'variable',
    name: 'variable name',
    description: 'Q0max, ...',
  })
  .addTextInput({
    path: 'api',
    name: 'api',
    description: 'http://localhost:3000/api/ds/query',
  });
});
