import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2, Button, Input } from '@grafana/ui';

interface Props extends PanelProps<SimpleOptions> {}

interface Error {
  id: number;
  parameter: string;
  message: string;
}

const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    input: css`
      position: relative;
      width: 80%;

    `,
    button: css`
      position: relative;
      padding: 15px;
      margin-left: 2%;
    `,
    li: css`
      display: flex;
      margin-bottom: 5px;
    `,
  };
};

export const SimplePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  //console.log(data.request?.targets[0].datasource?.uid);
  const styles = useStyles2(getStyles);
  const [errors, setErrors] = useState<Error[]>([]);
  const [editingError, setEditingError] = useState<number | null>(null);
  const [editedMessage, setEditedMessage] = useState<string>('');
  const [newErrorMessage, setNewErrorMessage] = useState('');
  
  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string = "",
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource:{
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  
  const fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };
  

  const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';
  const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier
  const var2 = replaceVariables(options.variable);
  console.log("options.variable", var2);
  const rawSql = `SELECT * FROM errors WHERE param = '${var2}' ORDER BY id ASC`;
  //const rawSql = 'SELECT * FROM errors WHERE param = '${var2}' ORDER BY id ASC';
  //const rawSql = replaceVariables('SELECT * FROM errors WHERE param = \'${var2}\' ORDER BY id ASC');
  console.log("rawSQL", rawSql);
  //const rawSql = 'SELECT * FROM errors WHERE param = '${var2}' ORDER BY id ASC';
  const selectQuery = createQuery('now-1h', 'now', datasourceUID, rawSql);
  const headers = {'Content-Type': 'application/json'};

  useEffect(() => {
    fetchData(grafanaApiBaseUrl, method, headers, selectQuery)
      .then(data => {
        if (data) {
          const ids = data.results.A.frames[0].data.values[0];
          const parameters = data.results.A.frames[0].data.values[1];
          const messages = data.results.A.frames[0].data.values[2];
  
          const errorsData = ids.map((id: number, index: number) => ({
            id,
            parameter: parameters[index],
            message: messages[index],
          }));
  
          setErrors(errorsData);
        }
      });
  }, []);

  const handleEdit = (index: number) => {
    setEditingError(index);
    setEditedMessage(errors[index].message);
  };

  const handleSave = (index: number, errorId: number) => {
    const newErrors = [...errors];
    newErrors[index].message = editedMessage;
    setErrors(newErrors);
    setEditingError(null);
    const updateSql = `UPDATE errors SET error = '${editedMessage}' WHERE id = ${errorId}`;
    const updateQuery = createQuery('now-1h', 'now', datasourceUID, updateSql);
    fetchData(grafanaApiBaseUrl, method, headers, updateQuery);

  };

  const handleCancel = () => {
    setEditingError(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedMessage(event.target.value);
  };

  const handleDelete = (index: number, errorId: number) => {
    const deleteSql = `DELETE FROM errors WHERE id = ${errorId}`;
    const deleteQuery = createQuery('now-1h', 'now', datasourceUID, deleteSql);
    fetchData(grafanaApiBaseUrl, method, headers, deleteQuery);

    const updatedErrors = [...errors];
    updatedErrors.splice(index, 1);
    setErrors(updatedErrors);

  };

  const handleAdd = () => {
    //console.log(errors[0].parameter);
    const variable = replaceVariables(options.variable);
    console.log("variable", variable);
    const insertSql = `INSERT INTO errors (param, error) VALUES ('${variable}', '${newErrorMessage}');`;
    console.log("insertSQL", insertSql);
    const insertQuery = createQuery('now-1h', 'now', datasourceUID, insertSql);
    console.log(insertSql);

    fetchData(grafanaApiBaseUrl, method, headers, insertQuery);
    
    
    console.log(variable);
    setErrors([...errors, { id: errors.length + 1, parameter: options.variable, message: newErrorMessage }]);
    setNewErrorMessage('');
  };

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <ul>
        <li className={styles.li}>
          <Input
            value={newErrorMessage}
            onChange={(e) => setNewErrorMessage(e.currentTarget.value)}
            placeholder="Enter new error message"
            className={styles.input}
          />
          <Button onClick={handleAdd} className={styles.button}>Add</Button>
        </li>
      </ul>
      <ul
        className={cx(
          css`
            height: ${height-20}px;
            overflow-y: auto; // Enable vertical scrolling
          `
        )}>
        {errors.map((error, index) => {
          const isEditing = editingError === index;
          return (
            
            <li key={index} className={styles.li}>
              <Input
                type="text"
                value={isEditing ? editedMessage : error.message}
                onChange={handleChange}
                disabled={!isEditing}
                className={styles.input}
              />
              {isEditing ? (
                <>
                  <Button onClick={() => handleSave(index, error.id)} className={styles.button}>Save</Button>
                  <Button variant="destructive" onClick={handleCancel} className={styles.button}>Cancel</Button>
                </>
              ) : (
                <>
                  <Button onClick={() => handleEdit(index)} className={styles.button}>Edit</Button>
                  <Button variant="destructive" onClick={() => handleDelete(index, error.id)} className={styles.button}>Delete</Button>
                </>
              )}
            </li>
          );
        })}
      </ul>
      
    </div>
  );
};